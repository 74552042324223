<template>
  <div>
    <h1 style="text-align: center">Impressum</h1>
    Copyright todo ???
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped>

</style>